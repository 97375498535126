import { render, staticRenderFns } from "./UIPetanquePlayingStyle.vue?vue&type=template&id=799f5f80&scoped=true&"
import script from "./UIPetanquePlayingStyle.vue?vue&type=script&lang=ts&"
export * from "./UIPetanquePlayingStyle.vue?vue&type=script&lang=ts&"
import style0 from "./UIPetanquePlayingStyle.vue?vue&type=style&index=0&id=799f5f80&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "799f5f80",
  null
  
)

export default component.exports