import { render, staticRenderFns } from "./PetanqueLeague.vue?vue&type=template&id=3e5f6528&scoped=true&"
import script from "./PetanqueLeague.vue?vue&type=script&lang=ts&"
export * from "./PetanqueLeague.vue?vue&type=script&lang=ts&"
import style0 from "./PetanqueLeague.vue?vue&type=style&index=0&id=3e5f6528&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e5f6528",
  null
  
)

export default component.exports