import { render, staticRenderFns } from "./UIPetanqueTutorial.vue?vue&type=template&id=5cd9ee56&scoped=true&"
import script from "./UIPetanqueTutorial.vue?vue&type=script&lang=ts&"
export * from "./UIPetanqueTutorial.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cd9ee56",
  null
  
)

export default component.exports