import { render, staticRenderFns } from "./UIPetanqueEndScore.vue?vue&type=template&id=38e7fabe&scoped=true&"
import script from "./UIPetanqueEndScore.vue?vue&type=script&lang=ts&"
export * from "./UIPetanqueEndScore.vue?vue&type=script&lang=ts&"
import style0 from "./UIPetanqueEndScore.vue?vue&type=style&index=0&id=38e7fabe&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38e7fabe",
  null
  
)

export default component.exports