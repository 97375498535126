var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"send-bird-profile",class:[
    _vm.schemeClass,
    _vm.dragEnabled && !_vm.isReady ? 'is-drag' : '',
    _vm.inDrag ? 'in-drag' : '',
    _vm.isDragging ? 'dragging' : '',
    _vm.isReady ? 'ready' : '',
    _vm.filled ? 'filled' : '',
    'is-petanque'
  ],attrs:{"data-id":_vm.id,"draggable":_vm.filled && _vm.dragEnabled && !_vm.isReady},on:{"dragstart":_vm.dragstart,"dragend":_vm.dragend,"dragenter":function($event){$event.preventDefault();return _vm.dragenter.apply(null, arguments)},"dragover":function($event){$event.preventDefault();},"dragleave":_vm.dragleave,"drop":function($event){$event.preventDefault();return _vm.dropFn.apply(null, arguments)}}},[(!_vm.filled)?_c('button',{staticClass:"slot",attrs:{"disabled":!_vm.emptySlotEnabled},on:{"click":_vm.onClickEmptySlot}},[_c('p',{staticClass:"title-h2"},[_vm._v(_vm._s(_vm.$t('sendbird.create.profiletitle')))]),_c('p',{staticClass:"title-h3"},[_vm._v(_vm._s(_vm.$t('sendbird.create.profiledescr')))])]):_c('div',[_c('div',{staticClass:"avatar-image"},[_c('div',{staticClass:"avatar rounded"},[(_vm.avatar)?_c('img',{attrs:{"src":_vm.avatar,"alt":"","draggable":"false"}}):_c('img',{attrs:{"src":require("@/assets/images/petanques/avatar-master.png"),"alt":"","draggable":"false"}})]),_c('div',{staticClass:"cup"},[(_vm.filled)?_c('picto',{attrs:{"name":_vm.levelIcon,"draggable":"false"}}):_vm._e(),_c('p',{staticClass:"cta-small"},[_vm._v(_vm._s(_vm.levelTitle))])],1)]),_c('p',{staticClass:"title-h3 name"},[_vm._v(" "+_vm._s(_vm.name)+" "),(!_vm.isReady && !_vm.isMe)?_c('spinner'):_vm._e(),(_vm.showStarIcon)?_c('icon',{staticClass:"star",attrs:{"name":"icon-host"}}):_vm._e()],1),(_vm.isReady)?_c('div',{staticClass:"ready"},[_c('p',{staticClass:"cta-bold"},[_vm._v(_vm._s(_vm.$t('sendbird.ready')))])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }