import { render, staticRenderFns } from "./PetanqueQuickGame.vue?vue&type=template&id=56632477&scoped=true&"
import script from "./PetanqueQuickGame.vue?vue&type=script&lang=ts&"
export * from "./PetanqueQuickGame.vue?vue&type=script&lang=ts&"
import style0 from "./PetanqueQuickGame.vue?vue&type=style&index=0&id=56632477&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56632477",
  null
  
)

export default component.exports